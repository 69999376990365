import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
//
import { renderLastPay } from 'src/utils/helpers';
import MemberQuickPayForm from './member-quick-pay-form';
import MemberQuickEditForm from './member-quick-edit-form';

// ----------------------------------------------------------------------
const baseImgUrl = "https://res.cloudinary.com/sanjua/image/upload/v1688669219/";

export default function MemberTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
  const { avatarUrl, lastPay, profileId, status, info, monthsDue, image } = row;

  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const quickPay = useBoolean();

  const popover = usePopover();

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell sx={{ display: 'flex', alignItems: 'center', width: 300 }}>
          <Avatar alt={profileId?.names} src={`${baseImgUrl}${image?.src}.${image?.format}`} sx={{ mr: 2 }} />

          <ListItemText
            primary={`${profileId?.names} ${profileId?.lastName}`}
            secondary={profileId?.email}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', width: 140 }}><ListItemText
          primary={
            <Typography variant="body2" noWrap>
              {info?.category}
            </Typography>
          }
          secondary={
            <Typography variant="body2" noWrap>
              ({info?.subcategory})
            </Typography>
          }
        /></TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', width: 140 }}>{lastPay && renderLastPay(lastPay)}</TableCell>

        <TableCell>
          <Label
            variant="soft"
            color={
              (!monthsDue && status === 'active' && 'success') ||
              (status === 'pending' && 'warning') ||
              (status === 'banned' && 'error') ||
              'default'
            }
          >
            {monthsDue ? `Debe ${monthsDue} meses` : status}
          </Label>
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Pagar" placement="top" arrow>
            <IconButton color="success" onClick={quickPay.onTrue}>
              <Iconify icon="ion:logo-usd" />
            </IconButton>
          </Tooltip>

          {/* <Tooltip title="Quick Edit" placement="top" arrow>
            <IconButton color={quickEdit.value ? 'inherit' : 'default'} onClick={quickEdit.onTrue}>
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          </Tooltip> */}

          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MemberQuickEditForm currentUser={row} open={quickEdit.value} onClose={quickEdit.onFalse} />
      <MemberQuickPayForm currentMember={row} open={quickPay.value} onClose={quickPay.onFalse} />

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {/* <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Eliminar
        </MenuItem> */}

        <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Editar
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}

MemberTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool
};
