export const MEMBER_TYPE_ENUMS = {
  player: 'Jugador',
  socio: 'Socio',
  student: 'Alumno',
  employee: 'Empleado',
}

export const MEMBER_TYPE_OPTIONS = [
  { value: 'player', label: 'Jugador' },
  { value: 'socio', label: 'Socio' },
  { value: 'student', label: 'Alumno' },
  { value: 'employee', label: 'Empleado' }
];

export const MEMBER_CATEGORY_OPTIONS = [
  {
    value: 'futbol',
    label: 'Futbol',
    subcategories: [
      {
        value: 'cuarta',
        label: 'Cuarta',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500

      },
      {
        value: 'quinta',
        label: 'Quinta',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500
      },
      {
        value: 'sexta',
        label: 'Sexta',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500
      },
      {
        value: 'septima',
        label: 'Septima',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500
      },
      {
        value: 'octava',
        label: 'Octava',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500
      },
      {
        value: 'novena',
        label: 'Novena',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500
      },
      {
        value: 'promo',
        label: 'Promo',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500
      },
      {
        value: 'minpromo',
        label: 'Mini Promo',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500
      },
      {
        value: '2006',
        label: '2006',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500
      },
      {
        value: '2007',
        label: '2007',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500
      },
      {
        value: '2008',
        label: '2008',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500
      },
      {
        value: '2009',
        label: '2009',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500
      },
      {
        value: '2010',
        label: '2010',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500
      },
      {
        value: '2011',
        label: '2011',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500
      },
      {
        value: '2012',
        label: '2012',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500
      },
      {
        value: '2013',
        label: '2013',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500
      },
      {
        value: '2014',
        label: '2014',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500
      },
      {
        value: '2015',
        label: '2015',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500
      },
      {
        value: '2016',
        label: '2016',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500
      },
      {
        value: '2017',
        label: '2017',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500
      },
      {
        value: '2018',
        label: '2018',
        amount: 4000,
        amountInscription: 400,
        amountLate: 500
      }
    ]
  },
  {
    value: 'futbol_femenino',
    label: 'Futbol Femenino',
    subcategories: [
      {
        value: 'primera',
        label: 'Primera',
        amount: 3500,
        amountInscription: 4500,
        amountLate: 0

      },
      {
        value: 'reserva',
        label: 'Reserva',
        amount: 3500,
        amountInscription: 4000,
        amountLate: 0
      },
      {
        value: 'inferiores',
        label: 'Inferiores',
        amount: 3500,
        amountInscription: 4000,
        amountLate: 0
      }
    ]
  },
  {
    value: 'hockey_femenino',
    label: 'Hockey Femenino',
    subcategories: [
      {
        value: 'primera',
        label: 'Primera',
        amount: 1500,
        amountInscription: 0,
        amountLate: 300
      },
      {
        value: 'reserva',
        label: 'Reserva',
        amount: 1500,
        amountInscription: 0,
        amountLate: 300
      },
      {
        value: 'inferiores',
        label: 'Inferiores',
        amount: 1500,
        amountInscription: 0,
        amountLate: 300
      }
    ]
  },
];

export const MEMBER_CATEGORY_SOCIO_OPTIONS = [
  {
    value: 'activo',
    label: 'Activo',
    subcategories: [
      {
        value: 'popular',
        label: 'Popular ($3000)',
        amount: 3000,
        amountInscription: 0
      },
      {
        value: 'platea',
        label: 'Platea ($3500)',
        amount: 3500,
        amountInscription: 0
      }
    ]
  },
  {
    value: 'jubilado',
    label: 'Jubilado',
    subcategories: [
      {
        value: 'general',
        label: 'General ($2500)',
        amount: 2500,
        amountInscription: 0
      },
    ]
  },
  {
    value: 'padre_tutor',
    label: 'Padre/Madre/Tutor',
    subcategories: [
      {
        value: 'general',
        label: 'General ($2500)',
        amount: 2500,
        amountInscription: 0
      },
    ]
  },
  {
    value: 'juvenil',
    label: 'Juvenil',
    subcategories: [
      {
        value: 'general',
        label: 'General ($2500)',
        amount: 2500,
        amountInscription: 0
      },
    ]
  },
];

export const MEMBER_DISCIPLINE_OPTIONS = [
  { value: 'futbol', label: 'Futbol' },
  { value: 'futsal', label: 'Futsal' },
  { value: 'futsalFemenino', label: 'Futsal Femenino' },
  { value: 'hockey', label: 'Hockey' },
  { value: 'handball', label: 'HandBall' },
  { value: 'handballFemenino', label: 'HandBallFemenino' }
];

export const MEMBER_SOCIO_CATEGORY_OPTIONS = [
  { value: 'activo', label: 'Activo (popular $3000)' },
  { value: 'activo_platea', label: 'Activo (platea $3500)' },
  { value: 'jubilado', label: 'Jubilado ($2500)' },
  { value: 'padre_tutor', label: 'Padre/Madre/Tutor ($2500)' },
  { value: 'juvenil', label: 'Juvenil ($2500)' }
];

export const MEMBER_DIVISION_OPTIONS = [
  { value: 'primera', label: 'Primera', parent: 'futbol' },
  { value: 'cuarta', label: 'Cuarta', parent: 'futbol' }
];

export const MEMBER_QUANTITY_OPTIONS = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 12, label: '12' },
  { value: 18, label: '18' },
  { value: 24, label: '24' },
  { value: 36, label: '36' }
];

export const MEMBER_PAYMENT_METHODS_OPTIONS = [
  { value: 'cash', label: 'Efectivo' },
  { value: 'transfer', label: 'Transferencia' },
  { value: 'mercadopago', label: 'Link Mercado Pago' }
];

export const MEMBER_CUSTOM_FIELDS = {
  player: {
    category: 'futbol',
    division: 'quinta',
    relation: null,
    temporal: false,
  },
  socio: {
    nro: null,
    category: 'activo',
  },
  student: null,
  employee: null
}