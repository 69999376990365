import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
// utils
import { fData } from 'src/utils/format-number';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// components
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
  RHFSelect,
  RHFAutocomplete,
} from 'src/components/hook-form';
import QRCode from "react-qr-code";
import { toJpeg } from 'html-to-image';
import Image from 'src/components/image/image';
import axios, { endpoints } from 'src/utils/axios';
import Chip from 'src/theme/overrides/components/chip';
import {
  USER_GENDER_OPTIONS,
  JOB_WORKING_SCHEDULE_OPTIONS,
  MEMBER_CATEGORY_SOCIO_OPTIONS,
  MEMBER_CATEGORY_OPTIONS
} from '../../_mock';

// ----------------------------------------------------------------------
const baseImgUrl = "https://res.cloudinary.com/sanjua/image/upload/v1688669219/";

export default function MemberNewEditForm({ currentMember, type = 'player' }) {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const ref = useRef(null);
  const [position, setPosition] = useState({ pos: 'relative', left: 0 });
  const [categoryOptions, setCategoryOptions] = useState(type === 'player' ?
    MEMBER_CATEGORY_OPTIONS : MEMBER_CATEGORY_SOCIO_OPTIONS);

  const NewUserSchema = Yup.object().shape({
    names: Yup.string().required('El nombre es requerido'),
    lastName: Yup.string().required('El apellido es requerido'),
    gender: Yup.string().required('El sexo es requerido'),
    // birthday: Yup.mixed().nullable().required('Birthday date is required'),
    // email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    // phone: Yup.string().required('Phone number is required'),
  });

  const defaultValues = useMemo(
    () => ({
      type,
      names: currentMember?.profileId?.names || '',
      info: currentMember?.info || type === 'player' ? {
        category: 'futbol',
        subcategory: 'quinta'
      } : {
        category: 'activo',
        subcategory: 'popular'
      },
      lastName: currentMember?.profileId?.lastName || '',
      birthday: currentMember?.profileId?.birthday || null,
      idType: currentMember?.profileId?.idType || 'dni',
      idNumber: currentMember?.profileId?.idNumber || '',
      idTypeTutor: currentMember?.idTypeTutor || 'dni',
      idNumberTutor: currentMember?.idNumberTutor || '',
      gender: currentMember?.profileId?.gender || 'male',
      email: currentMember?.profileId?.email || '',
      phone: currentMember?.profileId?.phone || '',
      additionalInfo: [
        { key: 'years', value: '37' },
        { key: 'color', value: 'blue' },
      ] || [],
      image: currentMember?.image || null
    }),
    [currentMember, type]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    setTimeout(() => {
      setPosition({ pos: 'absolute', left: -30000 })
    }, 3000)
  }, []);

  useEffect(() => {
    setValue('type', type)
    // @TODO: Luego chequear por que usaba esto.
    if (currentMember) {
      setValue('info', currentMember.info)
    }
  }, [type, setValue, currentMember]);

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      delete data.avatarUrl;
      if (currentMember) {
        await axios.put(`${endpoints.member.update}/${currentMember._id}`, data);
      } else {
        await axios.post(endpoints.member.create, data);
      }
      reset();
      enqueueSnackbar(currentMember ? 'Update success!' : 'Create success!');
      router.push(paths.dashboard.member.list);
    } catch (error) {
      console.error(error);
    }
  });

  const uploadImage = useCallback(
    async (file) => {
      const bodyContent = new FormData();
      bodyContent.append("institution", 'atleticoalianza');
      bodyContent.append("file", file);
      const { data } = await axios.post(endpoints.member.createImage, bodyContent);
      setValue('image', {
        src: data.public_id,
        id: data.asset_id,
        format: data.format
      })
    }, [setValue]
  );

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        uploadImage(file);
        setValue('avatarUrl', newFile, { shouldValidate: true });
      }
    },
    [setValue, uploadImage]
  );

  const generateCredential = () => {
    if (ref.current === null) {
      return
    }

    toJpeg(ref.current, { cacheBust: true, })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = `${currentMember.profileId.idNumber}.jpeg`
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>

        <Grid xs={12} md={4}>
          <Card sx={{ pt: 10, pb: 5, px: 3 }}>
            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="avatarUrl"
                value={`${baseImgUrl}${values?.image?.src}.${values?.image?.format}`}
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 3,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.disabled',
                    }}
                  >
                    Tipos permitidos *.jpeg, *.jpg, *.png
                    <br /> tamaño maximo {fData(3145728)}
                  </Typography>
                }
              />
            </Box>

            <Stack justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
              <Button variant="soft" color="success" onClick={generateCredential}>
                Descargar Credencial
              </Button>
            </Stack>

            {currentMember && (
              <Stack justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
                <Button variant="soft" color="error">
                  Eliminar miembro
                </Button>
              </Stack>
            )}
          </Card>
        </Grid>

        <Grid xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >

              <RHFSelect native name="info.category" label="Categoria" InputLabelProps={{ shrink: true }}>
                {categoryOptions.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </RHFSelect>

              <RHFSelect native name="info.subcategory" label="SubCategoria" InputLabelProps={{ shrink: true }}>
                {categoryOptions.find(
                  item => item.value === values?.info?.category
                )?.subcategories?.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </RHFSelect>

              {type === 'socio' && <>
                <RHFTextField name="info.nro" label="Numero" InputLabelProps={{ shrink: true }} />
              </>}

              <RHFTextField name="names" label="Nombres" InputLabelProps={{ shrink: true }} />
              <RHFTextField name="lastName" label="Apellido" InputLabelProps={{ shrink: true }} />
              <RHFTextField name="idNumber" label="Dni" InputLabelProps={{ shrink: true }} />
              <RHFTextField name="idNumberTutor" label="Dni de padre o tutor" InputLabelProps={{ shrink: true }} />
              <RHFTextField name="email" label="Email" InputLabelProps={{ shrink: true }} />
              <RHFTextField name="phone" label="Teléfono" InputLabelProps={{ shrink: true }} />
              <Stack spacing={1.5} mb={2}>
                <Typography variant="subtitle2">Fecha de nacimiento</Typography>
                <Controller
                  label="Fecha de nacimiento"
                  name="birthday"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      {...field}
                      value={new Date(values.birthday)}
                      format="dd/MM/yyyy"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />
              </Stack>

              <Stack spacing={1.5} mb={2}>
                <Typography variant="subtitle2">Sexo</Typography>
                <RHFSelect native name="gender">
                  {USER_GENDER_OPTIONS.map((gender) => (
                    <option key={gender.value} value={gender.value}>
                      {gender.label}
                    </option>
                  ))}
                </RHFSelect>
              </Stack>

              {/* <RHFTextField
                name="password"
                label="Password"
                type={password.value ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={password.onToggle} edge="end">
                        <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              /> */}
            </Box>

            {type === 'player' &&
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">Hermano o pariente.</Typography>
                  <RHFAutocomplete
                    name="info.relation"
                    disableCloseOnSelect
                    options={JOB_WORKING_SCHEDULE_OPTIONS.map((option) => option)}
                    getOptionLabel={(option) => option}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    renderTags={(selected, getTagProps) =>
                      selected.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          key={option}
                          label={option}
                          size="small"
                          color="info"
                          variant="soft"
                        />
                      ))
                    }
                  />
                </Stack>

                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">Jugador a prueba</Typography>
                  <RHFSwitch name="info.temporal" />
                </Stack>
              </Box>
            }

            {/* <Stack spacing={1.5}>
              <Typography variant="subtitle2">Informacion adicional</Typography>
              {values.additionalInfo.map((additional, k) => (
                <RHFTextField
                  key={additional.key}
                  name={`additionalInfo.[${k}].value`}
                  label={additional.key}
                  InputLabelProps={{ shrink: true }}
                />
              ))}
            </Stack> */}

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!currentMember ? 'Agregar' : 'Actualizar'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
      <Grid xs={12} md={12}>
        {currentMember && (
          <div
            ref={ref}
            style={{
              backgroundImage: `url(${baseImgUrl}instituciones/credencials/sanjuan/atleticoalianza.jpg)`,
              width: 1028,
              height: 638,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div style={Styles.row}>
              <div className='column-image' style={Styles.columnImage}>
                <Image src={`${baseImgUrl}${values?.image?.src}.${values?.image?.format}`} style={Styles.image} />
              </div>
              <div className='column-data' style={Styles.columnData}>
                <div style={Styles.names}>{currentMember.profileId.names}</div>
                <div style={Styles.lastNames}>{currentMember.profileId.lastName}</div>
                <div style={Styles.dni}>{currentMember.profileId.idNumber}</div>
              </div>
              <div className='column-qr' style={Styles.qr}>
                <div style={Styles.qrImage}>
                  <QRCode
                    size={336}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={currentMember.profileId.idNumber}
                    viewBox="0 0 256 256"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

      </Grid>
    </FormProvider >
  );
}

MemberNewEditForm.propTypes = {
  currentMember: PropTypes.object,
  type: PropTypes.string
};


const Styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
  },
  columnImage: {
    paddingTop: 243,
    paddingLeft: 69,
  },
  image: {
    width: 288,
    height: 346,
    borderRadius: 17
  },
  columnData: {
    paddingTop: 342,
    width: 352
  },
  names: {
    marginBottom: 20,
    fontSize: 30
  },
  lastNames: {
    marginBottom: 20,
    fontSize: 30
  },
  dni: {
    fontSize: 30
  },
  qr: {
    width: 230,
    paddingTop: 303
  },
  qrImage: {
    width: 230,
    height: 230
  }
}