import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// _mock
import { _userList } from 'src/_mock';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import axiosInstance, { endpoints } from 'src/utils/axios';
//
import MemberNewEditForm from '../member-new-edit-form';

// ----------------------------------------------------------------------

export default function MemberEditView({ id }) {
  const settings = useSettingsContext();
  const [currentMember, setCurrentMember] = useState(undefined);

  useEffect(() => {
    fetchMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchMember = async () => {
    const { data } = await axiosInstance.get(`${endpoints.member.list}/${id}`);
    setCurrentMember(data);
  }

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Edit"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },
          {
            name: 'User',
            href: paths.dashboard.user.root,
          },
          { name: currentMember?.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      {currentMember && <MemberNewEditForm currentMember={currentMember} type={currentMember?.type} />}
    </Container>
  );
}

MemberEditView.propTypes = {
  id: PropTypes.string,
};
